import React, {
    FormEvent,
    PropsWithChildren,
    ReactElement,
    useEffect,
    useState,
} from "react";
import styles from "./GradientImageBanner.module.scss";
import { encode } from "../../../shared/helpers";
import { messageIcons } from "../../../shared/icons";
import SubmitPopup from "../../../components/shared/Form/SubmitPopup";
import { Link } from "gatsby";

interface GradientImageBannerProps {
    data: any;
}

interface Popup {
    icon: ReactElement;
    title: string;
    message: string;
}

const GradientImageBanner = (
    props: PropsWithChildren<GradientImageBannerProps>
): ReactElement => {
    const [popup, setPopup] = useState<Popup>(null as any);

    const {
        banner_title,
        banner_text,
        banner_background,
        banner_cta_text,
        banner_cta_url,
        banner_gradient_color,
        banner_text_color,
    } = props.data;
    

    useEffect(() => {
        if (!popup) return;
        setTimeout(() => window.location.reload(), 3000);
    }, [popup]);

    return (
        <section
            className={styles.gradientImageBanner}
            style={{
                backgroundImage: `url(${banner_background.url})`
            }}
        >

            <div className={styles.textContainer}>
                {banner_title[0] && <h2>{banner_title[0]?.text}</h2>}
                {banner_text[0] && <p>{banner_text[0]?.text}</p>}

                {banner_cta_text[0] && (
                    <Link to={(banner_cta_url && banner_cta_url.slug) ? `/${banner_cta_url.slug}` : "/try-us" } >
                    <button className={styles.formButton} >
                        <span className={styles.submitText} >{banner_cta_text[0]?.text || "Go"} </span>
                    </button>
                    </Link>
                )}
            </div>

            {popup && (
                <SubmitPopup
                    icon={popup.icon}
                    title={popup.title}
                    message={popup.message}
                    handleClose={() => window.location.reload()}
                />
            )}
        </section>
    );
};

export default GradientImageBanner;
